<template>
  <!-- 赋码追溯 - 批次信息使用 key: bigvid 赋码追溯的批次信息和收货管理中的批次信息长得一样 -->
  <div class="item-content" v-show="visible">
    <el-form ref="childForm" :rules="rules" :model="formLabelAlign" label-position="top">
      <el-form-item style="text-align:left;" :label="localized(`label`)" prop="value">
        <div class="id-options" v-show="showVIDSetting" v-if="!formCannotEdit">
          <div style="width:50px; height: 40px; display: flex; justify-content: center; align-items: center;" @click="showVidDataSource">
            <img src="@/assets/new/vidsetting.png" width="16px" height="16px" />
          </div>
        </div>
        <div class="vid-list" v-bind:class="{ ve_disable: formCannotEdit }" :style="vidErrorStyle">
          <div class="left-content">
            <div class="vid-container" @click="viewVidList">
              <div style="height:40px; width: 100%; padding-top:40px; display:flex; align-items: center; justify-content: center; flex-direction:column">
                <span style="margin-left: 10px; line-height: 14px; flex: 1; text-align:center; width: 100%; font-size:14px; font-weight: 600">
                  {{ showContent }}</span>
              </div>
              <el-input v-model="showContent" style="visibility: hidden"></el-input>
              <span style="color: rgb(198, 202, 209)" v-if="itemdata == ''">{{ item.prompt || "" }}</span>
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="right-scanner" v-if="!vidDisable">
            <a v-show="!vidDisable" @click="scanVidList">
              <img src="@/assets/icons/dcu/scan.png" width="20px" />
            </a>
          </div>
        </div>
      </el-form-item>

      <!-- 赋码追溯的批次信息和收货管理中的批次信息长得一样，但是这里只有收获管理请求接口 -->
      <div v-show="item_name === 'delivervidlist' && Object.keys(receice).length !== 0">
        <div class="product-info">
          <div>{{ $t("common.product_name") }}: {{ receice && receice.product && receice.product.name || '-' }}</div>
          <span>{{ $t("common.product_count") }}: {{ receice && receice.product_bind && receice.product_bind.amount || 0 }}</span>
        </div>
      </div>

    </el-form>

    <big-vid-result-list ref="vidList"
      :isViewVidList="getIsViewVidList"
      :disabled="disabled"
      :vidDisable="vidDisable"
      :selectedScannerOption="selectedScannerOption"
      :formCannotEdit="formCannotEdit"
      :isBatchCode="currentType === 'bigvid' || item_name ==='delivervidlist'"
      :vidList="formLabelAlign.value"
      :vidCountLimit="vidCountLimit"
      :dcuKeyPath="hostKey"
      @confirm="confirmVidList"
      @openSetting="showVidDataSource"
    />

    <vid-setting ref="scannerSetting"
      @confirmSetting="confirmSetting"
      :dcuKeyPath="hostKey"
      :vidCountLimit="vidCountLimit"
      :isBatchCode="currentType === 'bigvid'"
      :disabled="disabled"
      :formCannotEdit="formCannotEdit"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import callApp from "@/services/sdk";
import language from "@/utils/lang";
import { commonStoreModule } from '@/store/module/common'
import VeTrueApi from '@/api/vetrue';
import * as R from 'ramda';

import VidSetting from '@/components/common/vidSetting.vue';
import BigVidResultList from '@/components/vidApp/bigVid/bigVidResultList.vue';

export default {
  name: "vid-for-app",
  props: [
    "jsonContent",
    "fatherContent",
    "datamodel",
    "disabled",
    "host",
    "listHost",
    "contextData",
  ],
  inject: {
    commonSave: {
      default: {}
    }
  },
  components: {
    VidSetting,
    BigVidResultList,
  },
  data() {
    return {
      flag: 0,
      currentVid: "",
      type: "",
      orderVidData: [],
      rfidData: [],
      failRfidData: [],
      currentSource: "local.scanner",
      formLabelAlign: {
        value: ""
      },
      vidType: 0,
      showContent: "",
      idType: this.$t("app.id_type_custom"),
      vidSourceType: "vid",
      isViewVidList: false,
      selectedScannerOption: "",
      receice: {}, // 收货管理数据
    };
  },
  computed: {
    ...mapGetters({
      scanType: "getCurrentScanType",
      formStatus: "getFormStatus",
      bigVid: "getBigVid",
      dataPointType: "getDataPointType",
      dataInfo: "getDataInfo",
      editStatus: "getFormStatus",
      storeDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    isCopy() {
      return this.$route.query && this.$route.query.copyRecord;
    },
    getIsViewVidList() {
      return this.type == 'view' || this.isViewVidList
    },
    showVIDSetting() {
      return true
    },
    vidCountLimit() {
      return this.fatherContent &&
        this.fatherContent.controlReference &&
        this.fatherContent.controlReference.max || 0
    },
    vidErrorStyle() {
      return "border-color: #dcdfe6"
    },
    currentType() {
      return this.dataPointType === "collectionUnBind" ||
        this.dataPointType === "collectionBind" ||
        this.dataPointType === "productManage" ?
        ((this.fatherContent.key || "").toLowerCase() === "bigvid" ?
          "bigvid" :
          "subvid") :
        "";
    },
    formCannotEdit() {
      return this.disableKeyPath || this.disabled
    },
    item() {
      return this.fatherContent.controlReference;
    },
    item_name() {
      return this.fatherContent.key;
    },
    rules() {
      return {
        value: [{
          type: "array",
          required: this.fatherContent.controlReference.required,
          message: this.$t('app.required_error'),
          trigger: "blur"
        }]
      };
    },
    itemdata: {
      get() {
        if (this.datamodel && this.datamodel.vidlist && !this.isCopy) {
          this.rfidData = this.datamodel.vidlist.map(vid => {
            let vidCode = 1;
            return {
              vid: vid,
              code: vidCode
            };
          });

          if (this.item.max === 1) return this.datamodel.vidlist[0];
          return this.datamodel.vidlist && this.datamodel.vidlist.length ?
            this.datamodel.vidlist.length :
            "";
        } else {
          return "";
        }
      },
      set(val) {
        if (!val) {
          this.$nextTick(_ => {
            this.showContent = "";
          });
          this.formLabelAlign.value = [];
          return false;
        }
        let data = {
          vidlist: val ? this.rfidData.map(data => data.vid) : [],
          type: 0
        };
        this.formLabelAlign.value = data.vidlist;
        let vidListLength = (data.vidlist && data.vidlist.length) || 0;
        if (this.item.max === 1 || vidListLength <= 1) {
          let showVid = data.vidlist[0];
          let vid = showVid.replace(showVid.substring(6, showVid.length - 6), "......");
          if (showVid && showVid.indexOf(',') > -1) {
            vid = showVid.split(',')[1]
          }

          if (data.vidlist[0].length <= 12) {
            vid = showVid;
          }
          this.$nextTick(_ => {
            this.showContent = vid;
          });
        } else {
          this.$nextTick(_ => {
            this.showContent = data.vidlist.length;
          });
        }
      }
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key;
    },
    vidDisable() {
      return this.disableKeyPath;
    },
    versionCode() {
      return this.$store.state.versionCode;
    },
    selectorVidData() {
      let datamodeVidList = (this.datamodel && !this.isCopy) ? this.datamodel.vidList : [];
      return (datamodeVidList || []).concat(this.orderVidData);
    },
    customIdInShip() {
      return commonStoreModule.getCustomIdOnReceive;
    },
    disableKeyPath() {
      let failure = this.formStatus === 'failureedit'
      let draft = this.formStatus === 'draftedit'
      let initStatus = this.formStatus === 'init'
      let receiveScene = this.$route.query.scene === "LOGISTIS_RECEIVE"
      return this.storeDisabledKeyPathList.includes(this.hostKey) || (!failure && !draft && !initStatus) || receiveScene
    },
  },
  watch: {
    bigVid: function () {
      this.flag++;
      this.$nextTick(() => {
        if (this.formStatus !== "init" && this.flag > 1) {
          if (this.currentType === "subvid") {
            this.rfidData = [];
            this.failRfidData = [];
            this.$nextTick(() => {
              this.itemdata = "";
            });
          }
        }
      });
    },
    formLabelAlign: {
      handler(val) {
        let type = this.selectedScannerOption.startsWith('nfc') ? 0 : 1;
        if (this.datamodel && !this.isCopy) {
          type = this.datamodel.type
        }
        if (val.value) {
          const vidStr = val.value[0];
          // HIGH vidlist 如果有 custom_id 则 type 为 1，否则 type 为 0
          if (vidStr && vidStr.indexOf(',') > -1) {
            type = 1;
          } else {
            type = 0;
          }
        }
        var data = {
          vidlist: val.value,
          type,
          num: (val.value && val.value.length) || 0
        };
        if (val.value === undefined || val.value === "" || val.value === []) {
          data = undefined;
        }
        console.log('formLabelAlign data', data, this.selectedScannerOption, this.datamodel);
        this.$emit("confirm", data);
      },
      deep: true
    },
    customIdInShip: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setCustomIdForReceive();
        }
      }
    },
    datamodel: {
      immediate: true,
      handler(val) { // 批次信息修改时显示 customid，mounted 里面无法立即拿到 datamodel，因为是异步的
        if (val) {
          if (this.item.max === 1) {
            let showVid = val && val.vidlist[0];

            let vid = showVid;
            if (showVid && showVid.length > 12) {
              vid = showVid.split(',')[1];
            }

            this.$nextTick(_ => {
              this.showContent = vid;
            });
          }
        }
      }
    }
  },
  created() {
    const _this = this;
    this.$store.state.vidlistComponents.push(this); // 缓存vid组件实例
    window["web.closeScanDialog"] = () => {
      this.$store.state.vidlistComponents.forEach(instance => {
        // 切换扫描类别时, 关闭扫描的弹窗
        // instance.$refs.vidList.dismiss();
      });
    };
  },
  mounted() {
    // HIGH 复制时依旧需要展示标签列表数据
    // if (this.datamodel && !this.isCopy) {
    if (this.datamodel) {
      if (this.datamodel.type === 1) {
        this.idType = this.$t("app.id_type_custom");
        this.vidSourceType = "customid";
        // HIGH 芯片类型数据原先处理会丢失掉vid，导致数据更新时出现数据校验不正确
        // this.formLabelAlign.value = (this.datamodel.vidlist || []).map(item => {
        //   let idlist = item.split(",");
        //   return idlist[1] || idlist[0];
        // });
        this.formLabelAlign.value = this.datamodel.vidlist;
        console.log(this.formLabelAlign, 'mounted this.formLabelAlign');
      } else {
        this.idType = this.$t("app.id_type_vid");
        this.vidSourceType = "vid";
        this.formLabelAlign.value = this.datamodel.vidlist;
      }
    }

    this.solveBigVid();

    if (this.datamodel && !this.isCopy && this.datamodel.type === 1) {
      this.datamodel.vidlist = this.datamodel.vidlist.map(item => {
        let idlist = item.split(",");
        return idlist[1] || idlist[0];
      });
    }

    if (this.item.max === 1) {
      let showVid = (this.datamodel && !this.isCopy) ? this.datamodel.vidlist[0] : "";
      let vid = showVid;
      if (showVid && showVid.length > 12) {
        vid = showVid.replace(showVid.substring(6, showVid.length - 6), "......");
      }

      this.$nextTick(_ => {
        this.showContent = vid;
      });
    } else {
      // HIGH 复制时依旧需要展示input输入框数据
      // this.showContent = (this.datamodel && !this.isCopy && this.datamodel.vidlist) ? this.datamodel.vidlist.length : "";
      this.showContent = (this.datamodel && this.datamodel.vidlist) ? this.datamodel.vidlist.length : "";

      if (this.datamodel && !this.isCopy && this.datamodel.vidlist && this.datamodel.vidlist.length == 1) {
        let showVid = this.datamodel && this.datamodel.vidlist[0];
        let vid = showVid;
        if (showVid && showVid.length > 12) {
          vid = showVid.replace(showVid.substring(6, showVid.length - 6), "......");
        }

        this.$nextTick(_ => {
          this.showContent = vid;
        });
      }
    }

    this.getStoredVidSource()
  },
  methods: {
    // 根据 batch_code 查询收货管理数据
    async getReceiveDataByBatchCodeOrReceiveId(batchCode) {
      console.log(batchCode, 'batchCode');
      const query = this.$route.query;
      const params = { batch_code: batchCode };
      let receiveData;
      try {
        receiveData = await VeTrueApi.getReceiveDataByBatchCodeOrReceiveId({ query, params });
      } catch (error) {
        const detail = R.pathOr('', ['response', 'data', 'detail'])(error);
        this.$message({ message: detail, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
      this.receice = receiveData;
    },
    showVidDataSource() {
      this.$refs.scannerSetting.show();
    },
    viewVidList() {
      this.isViewVidList = true
      this.$nextTick(() => {
        this.$refs.vidList.show();
      })
    },
    scanVidList() {
      if (!this.selectedScannerOption) {
        this.showVidDataSource()
      } else {
        this.isViewVidList = false
        this.$nextTick(() => {
          this.$refs.vidList.show();
        })
      }
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    },
    confirmSetting(newSetting) {
      this.$refs.vidList.clean(); // 清空旧弹窗内容
      this.$refs.vidList.dismiss();  // 关闭旧弹窗
      this.selectedScannerOption = newSetting;
      this.scanVidList(); // 设置点击确定时开启新弹窗
    },
    // 调用 app 获取设置面板选择的类型
    getStoredVidSource(callback) {
      let data = {
        key: this.hostKey
      }
      callApp("getSelectedVidSource", { type: "storage.getSelectedVidSource", data }, "cbGetSelectedVidSource", undefined, undefined)
        .then((resData) => {
          let defaultSetting = this.currentType === 'bigvid' ? "qr.customid_single" : "qr.customid_range"
          this.selectedScannerOption = resData || defaultSetting
        })
    },
    // 数据从下面子组件返回数据的地方
    confirmVidList(vidlist) {
      let result = [];

      vidlist.forEach(item => {
        if (item.code === 1) {
          result.push(item.vid);
        }
      });
      this.rfidData = vidlist

      // 批次信息应该就一个, 拿到后放到store中，enter 监听到后说明 batchCode 更新了，将刷新 model 数据
      const vids = vidlist[0].vid;
      if (vids) {
        console.log(vids, 'vids');
        const batchCode = vids?.split(',')[0];
        this.$store.dispatch("setBatchCodeWatch", batchCode);

        // 赋码追溯的批次信息和收货管理中的批次信息长得一样，但是这里只有收获管理请求接口
        if (this.item_name === 'delivervidlist') {
          this.getReceiveDataByBatchCodeOrReceiveId(batchCode);
        }
      }

      if (this.item.max === 1) {
        // 这边处理的原因是因为 confirm 后，页面上绑定的itemdata不会触发get钩子
        if (result.length === 1) {
          this.itemdata = result[0];
        } else if (result.length > 1) {
          this.itemdata = result.length;
        } else {
          this.itemdata = "";
        }
      } else {
        this.itemdata = result.length || "";
      }

      this.$nextTick(() => {
        this.solveBigVid();
      });
      callApp(
        "getData", {
          type: `device.closeScan`,
          data: this.selectedScannerOption
        },
        "cbGetData"
      );
    },
    solveBigVid() {
      if (this.currentType === "bigvid") {
        this.$store.dispatch("setBigVid", this.itemdata);
      }
    },
    isExisted(ary, item) {
      let flag;
      ary.forEach((data, index) => {
        var dataVid = data.vid && data.vid.toLowerCase();
        var itemVid = item.vid && item.vid.toLowerCase();
        if (dataVid === itemVid) {
          flag = index;
          this.$set(ary, index, item); // 重复的vid会被覆盖
        }
      });
      return flag !== undefined;
    },
    getVidErrorData(data) {
      if (typeof data === "object") {
        return data;
      } else {
        return eval("(" + data + ")");
      }
    },
    showOrderDialog() {
      this.vidSourceType = "customid";
      this.$refs.vidOrderDialog.show();
    },
    selectVidsFromOrder(vidList) {
      if (this.item.max > 1) {
        vidList.forEach(item => {
          if (item.vid && !this.isExisted(this.rfidData, item)) {
            this.rfidData.push(item);
            if (item.code != 1) {
              this.failRfidData.push({
                vid: item.vid,
                key: Math.random()
              });
            }
          } else {
            let index;
            if (item.code == 1) {
              this.failRfidData.filter((data, i) => {
                if (data.vid === item.vid) {
                  index = i;
                }
              });
            }
            index !== undefined && this.failRfidData.splice(index, 1);
          }
        });
        let scanCardDom = document.querySelector(".box-card .el-card__body");
        scanCardDom &&
          (scanCardDom.scrollTop =
            scanCardDom.scrollHeight - scanCardDom.offsetHeight + 40);
      } else {
        /// add messages
        this.rfidData = vidList;
        this.failRfidData = vidList.filter(vid => vid.code != 1);
      }
      this.orderVidData = this.rfidData;

      let result = [];
      this.rfidData.forEach(item => {
        if (item.code === 1) {
          result.push(item.vid);
        }
      });
      if (this.item.max === 1) {
        // 这边处理的原因是因为 confirm 后，页面上绑定的itemdata不会触发get钩子
        if (result.length === 1) {
          this.itemdata = result[0];
        } else if (result.length > 1) {
          this.itemdata = result.length;
        } else {
          this.itemdata = "";
        }
      } else {
        this.itemdata = result.length || "";
        if (result.length === 1) {
          this.itemdata = result[0];
        }
      }

      this.$nextTick(() => {
        this.solveBigVid();
      });
    },
    setCustomIdForReceive() {
      let customIdOnReceive = commonStoreModule.getCustomIdOnReceive;
      if (customIdOnReceive && this.fatherContent.key === "logistics_code" && (this.$route.query.scene && this.$route.query.scene === Scene.LOGISTIS_RECEIVE)) {

        this.selectVidsFromOrder([{
          vid: customIdOnReceive,
          code: 1
        }])
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.item-content {
  padding: 10px 0;
  margin: 10px 0;
}

.item-label {
  span {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
}

.top-line {
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.scan-item {
  line-height: 40px;
  border-bottom: 1px solid #e8e8e8;
}

.active-selected-scan {
  font-weight: 500;
  color: #000000;

  span {
    display: inline-block;
    float: right;
    line-height: 40px;
    font-weight: 700;
  }
}

.qr_items {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.qr_item_radio {
  justify-content: flex-start;
}

.vid-prefix-label {
  line-height: 20px;
  max-width: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #666;

  .right-border {
    width: 2px;
    height: 12px;
    background-color: #999;
  }
}

.vid-source-radio {
  display: block;
  margin-top: 30px;
  text-align: center;

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #000;
    background: #000;
  }

  .el-radio__input.is-checked+.el-radio__label {
    color: #000;
  }
}

.nfc,
.infrared,
.deviceTime,
.gps {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding-left: 14px;
  word-break: break-word;
  overflow: hidden;
  padding-right: 6px;

  a {
    float: right;
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
  }

  span {
    display: inline-block;
    height: 100%;
    width: calc(100% - 45px);
  }

  &:active {
    border-color: #409eff;
  }

  .scan {
    position: absolute;
    right: 4px;
    top: 14px;
  }
}

.vid-list {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .left-content {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .vid-container {
      flex: 1;
    }

    i {
      width: 10px;
    }
  }

  .right-scanner {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #dcdfe6;

    span {
      line-height: 24px;
    }

    a {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }
  }

  .scan {
    position: absolute;
    right: 4px;
    top: 14px;
  }
}

.vid-list-range {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  display: flex;
  justify-content: "flex-satrt";
  align-items: center;

  .left-content {
    padding: 10px;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: "flex-satrt";
    align-items: center;

    .vid-container {
      flex: 1;
    }

    i {
      width: 10px;
    }
  }

  .right-scanner {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #dcdfe6;

    span {
      line-height: 24px;
    }

    a {
      flex: 1;
      // text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }
  }

  .scan {
    position: absolute;
    right: 4px;
    top: 14px;
  }
}

.ve_disable {
  &:active {
    border-color: #dcdfe6;
  }
}

.scan_tip {
  margin-top: 10px;
  text-align: center;
  opacity: 0.8;
}

.box-card {
  .el-card__body {
    height: calc(100vh - 360px);
    overflow-y: scroll;

    .no-data {
      text-align: center;
      opacity: 0.5;
    }

    .scan-result {
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid lightgrey;

      .left {
        word-break: break-word;
      }

      .right {
        text-align: right;
        width: 90px;
      }
    }
  }
}

.id-options {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  top: -40px;
  right: 1px;
}

</style>
