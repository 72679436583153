



















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { shortVid, splitVid } from '@/utils/vid'
import VeTrueApi from '@/api/vetrue';
import { parseJwt } from '@/utils/format'

@Component({
  components: {},
  filters:{}
})
export default class BigVidInput extends Vue {

  public manualTag: string = '' // 单码 model

  /// props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  public dialogVisible: boolean = false
  public vidDetailDialogVisible: boolean = false
  public failRfidData: Array<any> = []
  public rfidData: Array<any> = []
  public currentVid: any = {}

  public customId: any = {
    begin: "",
    end: "",
    list: []
  }

  public vidListData: Array<any> = []

  public get idType(): any {
    if(this.selectedScannerOption.startsWith('nfc') || this.selectedScannerOption.indexOf('customid') < -1 ){
      return this.$t('app.id_type_vid')
    }
    return this.$t('app.id_type_custom')
  }

  public get vidResultDialogClass(): string {
    return "scan-dialog"
  }

  // 切换设置 - 切换弹窗，显示设置弹窗
  public toggleSetting(): void {
    this.$emit('openSetting') // 显示设置弹窗，传函数方式
  }

  // methods
  public show(): void {
    this.dialogVisible = true
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }

  public getVidErrorMessage(vidCode: any | undefined){
    if(vidCode && vidCode !== 1){
      return this.$t("app.viderror." + vidCode)
    }
  }

  public clean(): void {
    this.rfidData = []
    this.failRfidData = []
    this.vidListData = []
    this.$emit("confirm", this.vidListData)
  }

  public dismiss(): void {
    this.dialogVisible = false
  }

  public shortShowVid(vid: string): string {
    return shortVid(vid)
  }

  public devidVid(vid: string): string {
    return splitVid(vid)
  }

  public deleteVid(vidObject: any) {
    this.rfidData = this.rfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
    this.failRfidData = this.failRfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
  }

  public clearAll() {
    this.rfidData = []
    this.failRfidData = []
  }

  public scannedVidFromQrcode(vids: any) {
    this.appendVids(vids)
  }

  public appendVids(vids: any) {
    let filteredVidList = vids.filter((item: any) => {
      var existedVidList = this.rfidData.filter((vidObj: any) => {
        return vidObj.vid.toLowerCase() === item.vid.toLowerCase()
      })
      return existedVidList.length <= 0
    })
    this.rfidData = this.rfidData.concat(filteredVidList)
    if(this.rfidData.length > this.vidCountLimit){
      this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
    }
    this.failRfidData = this.rfidData.filter(item => {
      return item.code !== 1
    })
  }

  public showVidDetail(vid: any) {
    this.vidDetailDialogVisible = true
    this.currentVid = vid
  }

  public handleCloseDialog(): void {
    this.dialogVisible = false;
    this.manualTag = '';
    this.clearAll();
    this.customId.begin = '';
    this.customId.end = '';
  }

  // 判断是否已经存在输入vid
  public isVIdInclude(vid: string) {
    return this.rfidData.filter((item) => item.vid.split(",")[0] === vid.toLowerCase()).length !== 0;
  }

  // 手动输入批次
  public async handleIncreaseTag() {
    if (!this.manualTag) {
      const messageString = this.$t("app.please_entry_batch") as any
      this.$message({ message: messageString, type: "error", offset: 50, duration: 2000, center: true });
      return
    }
    if (this.isVIdInclude(this.manualTag)) {
      const msg = this.$t("datapoint.add_error_id_has_been_used") as any;
      this.$message({ message: msg, type: "error", offset: 50, duration: 2000, center: true });
      return
    }
    try {
      // 手动输入时传 custom_id_list
      const params = {
        query: this.$route.query,
        project_uuid: this.$route.query.project_uuid,
        custom_id_list: [this.manualTag],
        label_type: 'BATCH_CODE', // 商品标签    PRODUCT_CODE      批次       BATCH_CODE
        usable: true,
      }
      const list = await VeTrueApi.getUsableTagList(params);
      if (Object.keys(list).length === 0) {
        const msg = this.$t("datapoint.add_batch_dialog_end_error_id") as any;
        this.$message({ message: msg, type: "error", offset: 50, duration: 2000, center: true });
        return
      }
      // 如果有批次信息，需要根据vid去获取该批次码是否已经发货成功 调用的是 logistics-order-detail
      // 如果没有，直接接口返回404，并给出错误提示，比如没有发货信息
      // 如果有返回结果，那就需要拿当前用户的token里面的部门id和查询结果中 收货部门 id进行对比，如果相同，则说明有权限，否则提示 '无收货权限'
      const vid = list[0]?.vid;
      const logisticsParams = { batch_code: vid };
      try {
        const logisticsOrderDetail: any = await VeTrueApi.getReceiveDataByBatchCodeOrReceiveId({ query: this.$route.query, params: logisticsParams });
        const receiceDptId = logisticsOrderDetail?.receive_department?.id;
        const user = parseJwt(this.$route.query?.token);
        if (Number(receiceDptId) !== user.department_id) {
          const msg = this.$t('datapoint.no_receive_permissions') as any;
          this.$message({ message: msg, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
          return;
        }
      } catch (error: any) {
        const detail = error?.response?.data?.detail;
        this.$message({ message: detail, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
      this.rfidData = list.map((item: any) => {
        return {
          vid: item.custom_id ? `${item.vid},${item.custom_id}`: item.vid, // 增加逻辑判断
          code: 1
        }
      });

      if (this.rfidData.length > this.vidCountLimit) {
        this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
      }
    } catch (error: any) {
      const { response } = error;
      const msg = response.data.detail[0].msg;
      this.$message({ message: msg, type: "error", offset: 50, duration: 2000, center: true });
      return
    }
  }

  public clear() {
    this.dialogVisible = false
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    } else {
      this.rfidData = []
      this.failRfidData = []
    }
  }

  public delAllFailData() {
    this.rfidData = this.rfidData.filter(item => {
      return item.code === 1
    })
    this.failRfidData = []
  }

  public confirm() {
    this.vidListData = JSON.parse(JSON.stringify(this.rfidData))
    this.dialogVisible = false
    this.$emit("confirm", this.vidListData)
  }

  // lifecycle
  created() {

  }

  mounted() {
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }
}
