




























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { shortVid, splitVid } from '@/utils/vid'
import VidInput from '@/components/common/vidInput.vue'
import VidScanner from '@/components/common/vidScanner.vue'
import callApp from "@/services/sdk";

@Component({
  components: {
    VidInput,
    VidScanner,
  },
  filters:{}
})

export default class BigVidChip extends Vue {

  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  public customId: any = {
    begin: "",
    end: "",
    list: []
  }

  public dialogVisible: boolean = false
  public vidDetailDialogVisible: boolean = false
  public failRfidData: Array<any> = []
  public rfidData: Array<any> = []
  public currentVid: any = {}

  public vidListData: Array<any> = []

  public get idType(): any {
    if(this.selectedScannerOption.startsWith('nfc') || this.selectedScannerOption.indexOf('customid') < -1 ){
      return this.$t('app.id_type_vid')
    }
    return this.$t('app.id_type_custom')
  }

  public get vidResultDialogClass(): string {
    return "scan-dialog"
  }

  // 切换设置 - 切换弹窗，显示设置弹窗
  public toggleSetting(): void {
    this.$emit('openSetting') // 显示设置弹窗，传函数方式
  }

  public handleCloseDialog(): void {
    this.clearAll();
    this.customId.begin = '';
    this.customId.end = '';
    this.dialogVisible = false;
  }

  // methods
  public show(): void {
    this.dialogVisible = true
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }

  public getVidErrorMessage(vidCode: any | undefined) {
    if(vidCode && vidCode !== 1){
      return this.$t("app.viderror." + vidCode)
    }
  }

  public clean(): void {
    this.rfidData = []
    this.failRfidData = []
    this.vidListData = []
    this.$emit("confirm", this.vidListData)
  }

  public dismiss(): void {
    this.dialogVisible = false
  }

  public shortShowVid(vid: string): string {
    return shortVid(vid)
  }

  public devidVid(vid: string): string {
    return splitVid(vid)
  }

  public deleteVid(vidObject: any) {
    this.rfidData = this.rfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
    this.failRfidData = this.failRfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
  }

  public clearAll() {
    this.rfidData = []
    this.failRfidData = []
  }

  public appendVids(vids: any) {
    let filteredVidList = vids.filter((item: any) => {
      var existedVidList = this.rfidData.filter((vidObj: any) => {
        // 对比的字段要统一(带上了customId) 防止重复扫描
        return vidObj.vid.toLowerCase() === `${item.vid},${item.customId}`.toLowerCase();
      })
      return existedVidList.length <= 0
    })
    this.rfidData = this.rfidData.concat(filteredVidList)
    // 处理有 customId 的情况
    this.rfidData = this.rfidData.map((item: any) => {
      return {
        vid: item.customId ? `${item.vid},${item.customId}`: item.vid, // 增加逻辑判断
        code: 1
      }
    });

    if(this.rfidData.length > this.vidCountLimit){
      this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
    }
    this.failRfidData = this.rfidData.filter(item => {
      return item.code !== 1
    })
  }

  public scannedVidFromNfc(vids: any) {
    if (vids.length > 0 && vids[0].vid === '') {
      const messageString = this.$t("app.viderror.9012") as any
      this.$message({ message: messageString, type: "error", offset: 200, center: true, duration: 3000 });
      return
    }
    if (vids.length > 0 && vids[0].vid !== '' && !vids[0].customId) {
      const messageString = this.$t("app.viderror.50001") as any
      this.$message({ message: messageString, type: "error", offset: 200, center: true, duration: 3000 });
      return
    }
    this.appendVids(vids);
  }

  public showVidDetail(vid: any) {
    this.vidDetailDialogVisible = true
    this.currentVid = vid
  }

  public clear() {
    this.dialogVisible = false
    this.closeAndroidNfcTag()
    if (this.vidList && this.vidList.length > 0) {
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    } else {
      this.rfidData = []
      this.failRfidData = []
    }
  }

  public delAllFailData() {
    this.rfidData = this.rfidData.filter(item => {
      return item.code === 1
    })
    this.failRfidData = []
  }

  public confirm() {
    this.vidListData = JSON.parse(JSON.stringify(this.rfidData))
    this.dialogVisible = false
    this.closeAndroidNfcTag()
    this.$emit("confirm", this.vidListData)
  }

  public closeAndroidNfcTag() {
    callApp("getData", { type: `device.closeScan`, data: this.selectedScannerOption }, "cbGetData", undefined, undefined);
  }

  // lifecycle
  created() {

  }

  mounted() {
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }
}
