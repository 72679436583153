



































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { createRequestId, shortVid, splitVid } from '@/utils/vid'
import { BizType, Scene } from "@/utils/config";

import VidInput from '@/components/common/vidInput.vue'
import VidScanner from '@/components/common/vidScanner.vue'
import callApp from "@/services/sdk";

@Component({
  components: {
    VidInput,
    VidScanner
  },
  filters:{}
})
export default class BigVidSingle extends Vue {

  /// props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  public dialogVisible: boolean = false
  public vidDetailDialogVisible: boolean = false
  public failRfidData: Array<any> = []
  public rfidData: Array<any> = []
  public currentVid: any = {}

  public vidListData: Array<any> = []

  public get idType(): any {
    if(this.selectedScannerOption.startsWith('nfc') || this.selectedScannerOption.indexOf('customid') < -1 ){
      return this.$t('app.id_type_vid')
    }
    return this.$t('app.id_type_custom')
  }

  public get isIos(): boolean {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }

  public get isNFC(): boolean {
    return this.selectedScannerOption.length > 0 && this.selectedScannerOption.startsWith('nfc')
  }

  public get isScanQrSingle(): boolean {
    return this.selectedScannerOption.length > 0 && this.selectedScannerOption.startsWith('qr.customid_single')
  }

  public get vidResultDialogClass(): string {
    return "scan-dialog"
  }

  public get scanInfoTitle(): any {
    if(this.selectedScannerOption.startsWith('nfc')){
      return this.$t("app.scan_nfc_info")
    }
    return this.$t("app.scan_qr_info")
  }

  // methods
  public show(): void {
    this.dialogVisible = true
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }

    if (this.isNFC && !this.isIos && !this.isViewVidList) {
      // android nfc时，call app唤起NFC监听
      this.scanVid()
    }
  }
  public scanVid() {
    if(this.formCannotEdit){
      return
    }

    let labelType = this.isBatchCode ? "BATCH_CODE" : "PRODUCT_CODE"
    let tagType = this.$route.query.scene === Scene.LOGISTIS_SEND ? 'BATCH_CODE' : labelType
    let applyno = this.$route.query.applyno || ""

    let data = {
      max: this.vidCountLimit,
      bizType: BizType,
      labelType: tagType,
      applyno,
      project_uuid: this.$route.query.project_uuid,
    }
    let scanOptions = this.selectedScannerOption
    if(scanOptions == 'qr.customid_range'){
      scanOptions = 'qr.customid_single'
    }
    let dataSourceType = `dataSource.${scanOptions}`;

    const requestId = createRequestId();

    callApp("getData",{ type: dataSourceType, data }, "cbGetData",undefined, requestId)
      .then((resData: any) => {
        const windowObj = window as any
        windowObj["web.setVidData" + requestId] = (data: any) => {
          if (this.vidCountLimit - this.rfidData.length < 0) {
            this.$toast({
              message: this.$t("app.scan_limit"),
              position: "center",
              iconClass: "icon el-icon-warning",
              duration: "3000"
            });
            return;
          }

          this.appendVids(data.vids)
        };
      })
  }

  public dismiss(): void {
    this.dialogVisible = false
  }

  public clean(): void {
    this.rfidData = []
    this.failRfidData = []
    this.vidListData = []
    this.$emit("confirm", this.vidListData)
  }

  public shortShowVid(vid: string): string {
    return shortVid(vid)
  }

  public deleteVid(vidObject: any) {
    this.rfidData = this.rfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
    this.failRfidData = this.failRfidData.filter(item => {
      return item.vid !== vidObject.vid
    })
  }

  public devidVid(vid: string): string {
    return splitVid(vid)
  }

  public clearAll() {
    this.rfidData = []
    this.failRfidData = []
  }

  public scannedVidFromQrcode(vids: any) {
    this.appendVids(vids)
  }

  public appendVids(vids: any) {
    var existsVid = false
    if(vids.length > 0){
      let existVidList = this.rfidData.filter(item => {
        return item.vid === vids[0].vid
      })
      existsVid = existVidList.length > 0 ?  true : false
    }
    if(!existsVid){
      this.rfidData = this.rfidData.concat(vids)
    }

    if(this.rfidData.length > this.vidCountLimit){
      this.rfidData = this.rfidData.splice(this.rfidData.length - this.vidCountLimit, this.rfidData.length)
    }
    this.failRfidData = this.rfidData.filter(item => {
      return item.code !== 1
    })
  }
  public getVidErrorMessage(vidCode: any | undefined){
    if(vidCode && vidCode !== 1){
      return this.$t("app.viderror." + vidCode)
    }
  }

  public scannedVidFromNfc(vids: any) {
    this.appendVids(vids)
  }

  public showVidDetail(vid: any) {
    this.vidDetailDialogVisible = true
    this.currentVid = vid
  }

  public clear() {
    this.dialogVisible = false
    this.closeAndroidNfcTag()
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }else{
      this.rfidData = []
      this.failRfidData = []
    }
  }

  // 切换设置 - 切换弹窗，显示设置弹窗
  public toggleSetting(): void {
    this.$emit('openSetting') // 显示设置弹窗，传函数方式
  }

  public delAllFailData() {
    this.rfidData = this.rfidData.filter(item => {
      return item.code === 1
    })
    this.failRfidData = []
  }

  public confirm() {
    this.vidListData = JSON.parse(JSON.stringify(this.rfidData))
    this.dialogVisible = false
    this.closeAndroidNfcTag()
    this.$emit("confirm", this.vidListData)
  }

  public closeAndroidNfcTag() {
    callApp("getData",{ type: `device.closeScan`, data: this.selectedScannerOption },
      "cbGetData",
      undefined,
      undefined
    );
  }

  // lifecycle
  created() {

  }

  mounted() {
    if(this.vidList && this.vidList.length > 0){
      this.rfidData = this.vidList.map((item: any) => {
        return {vid: item, code: 1}
      })
    }
  }
}
