












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import VidInput from '@/components/common/vidInput.vue'
import BigVidSingle from '@/components/vidApp/bigVid/bigVidSingle.vue'
import BigVidChip from '@/components/vidApp/bigVid/bigVidChip.vue'
import BigVidInput from '@/components/vidApp/bigVid/bigVidInput.vue'

@Component({
  components: {
    VidInput,
    BigVidInput,
    BigVidSingle,
    BigVidChip,
  },
  filters:{}
})
export default class BigVidResultList extends Vue {

  /// props
  @Prop({ default: '' })
  public selectedScannerOption!: string

  @Prop({default: false})
  public disabled!: boolean

  @Prop({default: false})
  public vidDisable!: boolean

  @Prop({default: false})
  public formCannotEdit!: boolean

  @Prop({default: false}) /// view type or edit type
  public isViewVidList!: boolean

  @Prop({ default: ''})
  public dcuKeyPath!: string

  @Prop({ default: 0})
  public vidCountLimit!: number

  @Prop({default: false})
  public isBatchCode!: boolean

  @Prop({default: ()=>{ return [] }})
  public vidList!: Array<any>

  private scannerType: string = ''

  public generateScannerType() {
    if (this.selectedScannerOption === 'qr.customid_single') {
      this.scannerType = 'single' // 二维码扫批次
    } else if (this.selectedScannerOption === 'nfc.single') {
      this.scannerType = 'chip'; // nfc 扫批次
    } else if (this.selectedScannerOption === 'qr.single') {
      this.scannerType = 'input' // 手动输入批次
    } else {
      this.scannerType = 'single'
    }
  }

  // methods
  public show() {
    (this as any).generateScannerType()
    if (this.scannerType == 'single') {
      (this.$refs.singleVidList as any).show();
    } else if (this.scannerType == 'input') {
      (this.$refs.inputVidList as any).show();
    } else if (this.scannerType === 'chip') {
      (this.$refs.chipVidList as any).show();
    }
  }

  public clean() {
    (this as any).generateScannerType()
    if (this.scannerType == 'single') {
      (this.$refs.singleVidList as any).clean();
    } else if (this.scannerType == 'input') {
      (this.$refs.inputVidList as any).clean();
    } else if (this.scannerType === 'chip') {
      (this.$refs.chipVidList as any).clean();
    }
  }

  public dismiss(): void {
    if (this.scannerType == 'single') {
      (this.$refs.singleVidList as any).dismiss();
    } else if (this.scannerType == 'input') {
      (this.$refs.inputVidList as any).dismiss();
    } else if (this.scannerType === 'chip') {
      (this.$refs.chipVidList as any).dismiss();
    }
  }

  public confirm(vidlist: any) {
    this.$emit("confirm", vidlist)
  }

  public openSetting() {
    this.$emit('openSetting')
  }

  @Watch('selectedScannerOption', { immediate: true, deep: true })
  onArrayChanged(newVal: any, oldVal: any) {
    this.generateScannerType()
  }

  // lifecycle
  created() {

  }

  mounted() {
    this.generateScannerType()
  }
}
